

















































import Vue from 'vue'
import InventoryIcon from './Icons/InventoryIcon.vue'
import RequisitionIcon from './Icons/RequisitionIcon.vue'
import SalesIcon from './Icons/SalesIcon.vue'
import BillingIcon from './Icons/BillingIcon.vue'
import StockIcon from './Icons/StockIcon.vue'
import ContactIcon from './Icons/ContactIcon.vue'

interface UserInfos {
  firstname: string | null
  roles: string[]
}

export default Vue.extend({
  components: { InventoryIcon, RequisitionIcon, SalesIcon, BillingIcon, StockIcon, ContactIcon },
  props: {
    mode: {
      type: String,
      required: false,
      default: 'vertical',
    },
    // roles: {
    //   type: String[],
    //   required: true,
    // },
    userInfos: {
      type: Object as () => UserInfos,
      required: true,
    },
  },
  computed: {
    saleRestriction(): boolean {
      return this.userInfos?.roles.includes('ROLE_INVENTORY')
    },
    comptaRestriction(): boolean {
      return this.userInfos?.roles.includes('RESTRICTION_COMPTA')
    },
  },
  methods: {
    handleSelect(key: string) {
      if (this.$route.name !== key) {
        this.$router.push({ name: key })
      }
    },
  },
})
