

























import Vue from 'vue'
import { mapActions } from 'vuex'

interface UserInfos {
  firstname: string | null
}

export default Vue.extend({
  props: {
    userInfos: {
      type: Object as () => UserInfos,
      required: true,
    },
    user: {
      type: Object as () => Record<string, string> | null,
      required: false,
      default: null,
    },
    mode: {
      type: String,
      required: false,
      default: 'vertical',
    },
  },
  data() {
    return {
      documentationLink: process.env.VUE_APP_DOCUMENTATION,
    }
  },
  computed: {
    userAvatar(): string | null {
      return this.user ? `${process.env.VUE_APP_API_URL}/user/${this.user.id}/avatar` : null
    },
  },
  methods: {
    ...mapActions(['logout']),
    handleSelect(key: string) {
      if (this.$route.name !== key) {
        this.$router.push({ name: key })
      }
    },
    logoutUser() {
      this.$cookies.remove('authToken')

      // Destroying local storage
      localStorage.clear()

      this.logout()

      if (this.$router.currentRoute.name != 'home') {
        this.$router.push({ name: 'home' })
      }
    },
  },
})
